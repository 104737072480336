<template>
  <van-image class="com-image" :fit="fit" :src="src">
    <!-- <template #loading>
      <img
        class="error"
        :src="require(`../assets/images/common/${theme}/img_error.png`)"
      />
    </template>
    <template #error>
      <img
        class="error"
        :src="require(`../assets/images/common/${theme}/img_error.png`)"
      />
    </template> -->
  </van-image>
</template>
<script>
export default {
  props: {
    src: String,
    fit: {
      type: String,
      default: "contain",
    },
  },
};
</script>
<style lang="less" scoped>
.com-image {
  width: 100%;
  height: 100%;
}
:deep {
  .van-image__error,
  .van-image__loading {
    background: #000;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
